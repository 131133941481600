<template>
  <div class="container-fluid multiple-tabs mt-3">
    <Form @submit="saveAlbum" v-slot="{ errors }">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <Breadcrumb current_page="إضافة ألبوم"/>
      <button class="btn btn-publish mb-4" type="submit" :disabled="loading" @click="saveAlbum">حفظ</button>
    </div>

    <el-card>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="بيانات الالبوم" name="details">
          <AlbumDetails :details="album" :errors="errors" @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="الصور" name="gallery">
          <Gallery   @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="محركات البحث" name="seo">
          <SEO :seo-data="album" @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
          <SocialMediaTab :social-data="album" @update="handleOnChange"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import AlbumDetails from "@/views/albums/components/albumDetails";
import Album from "@/views/albums/index";
import Gallery from "@/views/albums/components/gallery";
import SEO from "@/views/albums/components/SEO";
import SocialMediaTab from "@/views/albums/components/socialMediaTab";
import {createAlbum} from "@/services/albums";
import {  Form } from "vee-validate";

export default {
  name      : "CreateAlbum",
  components: {
    SocialMediaTab,
    SEO,
    Gallery,
    Album,
    AlbumDetails,
    Breadcrumb,
    Form
  },
  data(){
    return {
      activeTab: 'details',
      loading:false,
      album: {
        name: '',
        description: '',
        cover_photo: '',
        is_main: false,
        use_watermark: false,
        seo_title: '',
        seo_description: '',
        social_title: '',
        social_image: '',
        social_description: '',
        is_active: false,
        photos: []
      }
    }
  },
  methods   : {
    handleOnChange(key, val)
    {
      this.album = {
        ...this.album,
        [key]: val
      }
    },
    saveAlbum()
    {
      this.loading = true
      createAlbum(this.album)
      .then(res=>{
        this.loading = false
        console.log(res)
        this.$router.push('/albums')
      })
      .catch(()=> {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.el-tabs__nav {
  float: right;
}

[dir=rtl] .el-dialog__headerbtn {
  right: unset;
  left: 20px;
}
</style>
