<template>
  <div class="row">
    <div class="col-md-5">
      <div class="form-group">
        <label for="name">عنوان الألبوم</label>
        <Field
            name="name"
            id="name"
            :value="details.name"
            @input="$emit('update', 'name', $event.target.value)"
            placeholder="عنوان الألبوم"
            class="form-control form-control-solid"
            :rules="isRequired"
            :class="{'border-red' : errors.title}"
        />
        <span class="text-error">{{ errors.title ? 'الرجاء ادخال عنوان الخبر'  : ''}}</span>
      </div>

      <div class="form-group mt-3">
        <label for="description">وصف الألبوم</label>
        <textarea
            rows="5"
            :value="details.description"
            @input="$emit('update', 'description', $event.target.value)"
            name="description"
            id="description"
            class="form-control input-rounded form-control-solid"
            placeholder="وصف الألبوم"
        ></textarea>
      </div>

      <div class="d-flex flex-wrap mt-3">
        <el-checkbox v-model="is_main" class="me-0 rounded-0" label="تثبيت في الرئيسية؟" border></el-checkbox>
        <el-checkbox v-model="use_watermark" class="me-0 rounded-0" label="استخدام علامة مائية؟" border></el-checkbox>
      </div>
    </div>
    <div class="col-md-3 mt-1">
      <div class="form-group">
        <label>الصورة</label>
        <label class="placeholder-image mt-0" @click="showUploadCenter=true;isSingleUpload=true">
          <img class="w-100" height="220" v-if="photo" :src="APP_URL + photo" alt="">
        </label>
      </div>
      <el-dialog :destroy-on-close=true
                 title="مركز الرفع"
                 v-model="showUploadCenter"
      >
        <UploadCenter :is-single-upload="isSingleUpload" @save="handleAddPhoto"/>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import UploadCenter from "@/components/uploadCenter";
import {Field} from "vee-validate";

export default {
  name      : "AlbumDetails",
  props     : ["details","errors"],
  components: {
    UploadCenter,
    Field
  },
  data()
  {
    return {
      showUploadCenter: false,
      isSingleUpload  : false,
      photo           : '',
      is_main         : false,
      use_watermark   : false
    }
  },
  watch   : {
    is_main() {
      this.$emit('update', 'is_main', !this.details.is_main)
    },
    use_watermark() {
      this.$emit('update', 'use_watermark', !this.details.use_watermark)
    },
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
  },
  methods : {
    isRequired(value) {
      return value ? true : 'This field is required';
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      if (this.showUploadCenter)
      {
        this.photo = photo.url;
        this.$emit('update', 'cover_photo', photo.url)
      }
      this.closeUploadCenter();
    }
  }

}
</script>

<style>
.el-checkbox.is-bordered {
  margin: 0;
  border-radius: 0;
  height: 42px;
}

.el-checkbox__label {
  padding-right: 8px;

}
</style>
